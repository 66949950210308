<template>
    <div
        data-cy="bottom-bar"
        class="z-10 flex items-center pb-[var(--beyond-safe-area-inset-bottom)]"
        :style="{
            boxShadow: '0px -3.41333px 6.82667px rgba(0, 0, 0, 0.07)',
            backgroundColor: bottomBarBackground === 'primary' ? null : bottomBarBackground,
        }"
        :class="[bottomBarClasses]"
    >
        <div
            data-cy="bottom-bar-homepage"
            class="flex-1 flex flex-col text-center items-center justify-center p-2 border-t-2 cursor-pointer"
            :class="[screen === 'homepage' ? bottomBarActiveClasses : 'border-transparent']"
            @click="setScreen('homepage')"
            @keydown.enter="setScreen('homepage')"
        >
            <PhIcon icon="house" :size="28" :weight="screen !== 'homepage' ? 'light' : 'fill'" />
            <span class="text-xs font-medium mt-0.5">Home</span>
        </div>

        <div
            v-if="bottomBarNotificationsButtonEnabled"
            data-cy="bottom-bar-notifications"
            class="flex-1 flex flex-col text-center items-center justify-center p-2 border-t-2 cursor-pointer relative"
            :class="[screen === 'notifications' ? bottomBarActiveClasses : 'border-transparent']"
            @click="setScreen('notifications')"
            @keydown.enter="setScreen('notifications')"
        >
            <PhIcon icon="bell" :size="28" :weight="screen !== 'notifications' ? 'light' : 'fill'" />
            <span class="text-xs mt-0.5">Notifications</span>
            <div
                v-if="unreadCount"
                data-cy="read-indicator"
                class="top-2 ml-7 absolute z-30"
                :class="[bottomBarNotificationIconColour]"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
                    <circle cx="4" cy="4" r="4" fill="currentColor" />
                </svg>
            </div>
        </div>

        <div
            data-cy="bottom-bar-identity"
            class="flex-1 flex flex-col text-center items-center justify-center p-2 border-t-2 cursor-pointer"
            :class="[screen === 'identity' ? bottomBarActiveClasses : 'border-transparent']"
            @click="setScreen('identity')"
            @keydown.enter="setScreen('identity')"
            v-if="identityStore.identity || (identificationSettings && hasStartupAuth && !identityStore.loaded)"
        >
            <PhIcon icon="identification-badge" :size="28" :weight="screen !== 'identity' ? 'light' : 'fill'" />
            <span class="text-xs mt-0.5">{{identificationSettings.title}}</span>
        </div>

        <div
            data-cy="bottom-bar-more"
            class="flex-1 flex flex-col text-center items-center justify-center p-2 border-t-2 cursor-pointer"
            :class="[screen === 'more' ? bottomBarActiveClasses : 'border-transparent']"
            @click="setScreen('more')"
            @keydown.enter="setScreen('more')"
        >
            <PhIcon icon="dots-three-outline" :size="28" :weight="screen !== 'more' ? 'light' : 'fill'" />
            <span class="text-xs mt-0.5">More</span>
        </div>
    </div>
</template>

<script setup>
    import tinycolor from 'tinycolor2'
    import { useRoute } from 'vue-router'

    const versionStore = useVersionStore()
    const dashboardStore = useDashboardStore()
    const identityStore = useIdentityStore()
    const appStore = useAppStore()
    const moduleHelpers = useModuleHelpers()
    const router = useIonRouter()
    const moduleStore = useModuleStore()
    const notificationsStore = useNotificationsStore()
    const route = useRoute()

    const { screen } = storeToRefs(dashboardStore)
    const { version } = storeToRefs(versionStore)
    const { unreadCount } = storeToRefs(notificationsStore)

    const bottomBarBackground = computed(() => get(version.value, 'bottom_bar_background_color'))
    const primaryColor = computed(() => get(version.value, 'primary_color'))
    const bottomBarNotificationsButtonEnabled = computed(() => get(version.value, 'notifications_enabled'))
    const identificationSettings = computed(() => get(version.value, 'identification_settings'))
    const hasStartupAuth = computed(() => get(version.value, 'has_startup_auth'))

    const bottomBarClasses = computed(() => {
        const pColor = tinycolor(primaryColor.value)
        const cColor = tinycolor(bottomBarBackground.value)
        switch (bottomBarBackground.value) {
            case 'primary':
                return pColor.isLight() ? 'bg-primary-600 text-gray-800' : 'bg-primary-600 text-white'
            case 'white':
            case null:
                return 'bg-white text-gray-600'
            default:
                return cColor.isLight() ? 'text-gray-800' : 'text-white'
        }
    })

    const bottomBarActiveClasses = computed(() => {
        const pColor = tinycolor(primaryColor.value)
        const cColor = tinycolor(bottomBarBackground.value)
        switch (bottomBarBackground.value) {
            case 'primary':
                return pColor.isLight() ? 'text-black border-black' : 'text-white border-white'
            case 'white':
            case null:
                return 'text-primary-600 border-primary-600'
            default:
                return cColor.isLight() ? 'text-black border-black' : 'text-white border-white'
        }
    })

    const bottomBarNotificationIconColour = computed(() => {
        const darkRed = 'text-red-500'
        const lightRed = 'text-red-400'
        const pColor = tinycolor(primaryColor.value)
        const cColor = tinycolor(bottomBarBackground.value)
        switch (bottomBarBackground.value) {
            case 'primary':
                return pColor.isLight() ? darkRed : lightRed
            case 'white':
            case null:
                return darkRed
            default:
                return cColor.isLight() ? darkRed : lightRed
        }
    })

    const setScreen = (screen) => {
        dashboardStore.setScreen(screen)
    }

    onMounted(() => {
        notificationsStore.fetch()
    })

</script>
