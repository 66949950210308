<template>
    <div class="relative min-h-full flex-1">
        <div id="map-container" class="absolute inset-0" :style="mapBackground" />
    </div>
</template>

<script setup>
    import leaflet from 'leaflet'
    import 'leaflet/dist/leaflet.css'

    const props = defineProps({
        config: Object,
    })

    const moduleHelpers = useModuleHelpers()

    const apiData = ref(props.config)

    // Avoid using ref here as vue 3 performs deep proxying that interferes with leaflet
    // unless we were to explicitly call toRaw() on the refs before using
    // See: https://stackoverflow.com/a/73588115
    let map = null
    let geoPin = null

    const latMax = get(apiData.value, 'data.latitude_max')
    const latMin = get(apiData.value, 'data.latitude_min')
    const lonMax = get(apiData.value, 'data.longitude_max')
    const lonMin = get(apiData.value, 'data.longitude_min')

    const mapBounds = leaflet.latLngBounds(leaflet.latLng(latMax, lonMin), leaflet.latLng(latMin, lonMax))
    const mapImage = get(apiData.value, 'data.image_url')
    const mapBackground = get(apiData.value, 'data.color', '#ffffff')

    const addGeoPin = () => {
        map.locate({
            setView: false,
            maxZoom: 22,
            watch: true,
            enableHighAccuracy: true,
        })

        geoPin = leaflet
            .marker([0, 0])
            .addTo(map)
            .bindPopup('Your Location')
    }

    const addPins = () => {
        const pins = get(apiData.value, 'items')

        // Points of interest on the map
        each(pins, (pin) => {
            const mLng = (pin.x_percent / 100) * (lonMax - lonMin) + lonMin
            const mLat = (pin.y_percent / 100) * (latMin - latMax) + latMax

            const colour = pin.color
            const svgIcon = `<svg style="width: 100%; height: 100%;" width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><circle cx="14" cy="14" r="14" fill="${ colour }"></circle><circle cx="14" cy="14" r="9" fill="#FFFFFF" ></circle></svg>`

            const mapIcon = leaflet.divIcon({
                html: svgIcon,
                iconSize: [18, 18],
                iconAnchor: [9, 9],
                popupAnchor: [0, -8],
            })

            const latLng = leaflet.latLng(mLat, mLng)

            leaflet.marker(latLng, {
                icon: mapIcon,
                id: pin.id,
            })
                .addTo(map)
                .bindPopup(pin.name)
        })
    }

    onMounted(() => {
        map = leaflet.map('map-container', {
            center: mapBounds.getCenter(),
            minZoom: 15,
            zoom: 17,
            maxZoom: 21,
            attributionControl: false,
            zoomControl: false,
            maxBounds: mapBounds,
        })

        leaflet.imageOverlay(mapImage, mapBounds)
            .addTo(map)

        leaflet.control.zoom({
            position: 'bottomleft',
        }).addTo(map)

        addGeoPin()
        addPins()

        map.on('locationfound', (e) => {
            if (mapBounds.contains(e.latlng)) {
                geoPin.setLatLng(e.latlng)
            }
        })

        // make sure the map has been sized correctly
        setTimeout(() => {
            map.invalidateSize()
        }, 1)

        moduleHelpers.setAnalyticsScreenFromConfig(props.config)
    })

</script>

<style lang="scss">
    .leaflet-marker-icon {
        @apply border-none bg-transparent;
    }
</style>
