<template>
    <IonPage>
        <IonContent data-cy="content-maintenance">
            <div class="py-20 px-12 flex items-center space-x-2 w-full h-full">
                <div class="w-full">
                    <Empty icon="error">
                        <template v-slot:icon>
                            <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M25.6903 49.5716C39.2042 49.5716 53.4688 35.0002 48.4688 18.5002L24.906 18.5002C11.3921 18.5002 2.01158 18.3634 1.99996 18.5004C0.303396 38.501 12.1764 49.5716 25.6903 49.5716Z" fill="currentColor" fill-opacity="0.1" />
                                <path d="M25.5 49.4287C38.7548 49.4287 49.5 38.6835 49.5 25.4287C49.5 12.1739 38.7548 1.42871 25.5 1.42871C12.2452 1.42871 1.5 12.1739 1.5 25.4287C1.5 38.6835 12.2452 49.4287 25.5 49.4287Z" stroke="currentColor" stroke-opacity="0.7" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M25.5 11.7148V25.7148" stroke="currentColor" stroke-opacity="0.7" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M25.5 34.9999C26.4468 34.9999 27.2143 34.2323 27.2143 33.2856C27.2143 32.3388 26.4468 31.5713 25.5 31.5713C24.5532 31.5713 23.7857 32.3388 23.7857 33.2856C23.7857 34.2323 24.5532 34.9999 25.5 34.9999Z" stroke="currentColor" stroke-opacity="0.7" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </template>

                        <template v-slot:title>
                            {{ title }}
                        </template>

                        <template v-slot:subtitle>
                            {{ message }}
                        </template>
                    </Empty>

                    <div class="flex flex-col items-center -mt-10">
                        <UIButton class="mb-6" @click="retry">Try again</UIButton>
                    </div>
                </div>
            </div>
        </IonContent>
    </IonPage>
</template>

<script setup>
    import { computed } from 'vue'
    import { useRoute } from 'vue-router'

    const route = useRoute()

    const retry = () => {
        window.location = '/'
    }

    const title = computed(() => {
        return window.localStorage.getItem('maintenance.title') || 'Under Maintenance'
    })

    const message = computed(() => {
        return window.localStorage.getItem('maintenance.message') || 'The App is undergoing maintenance. Please check back later.'
    })
</script>
