import { createAnimation } from '@ionic/vue'

export function useAnimations() {
    function launchAnimation(el, options) {
        // create an animation that expands and fades in
        const enterAnimation = createAnimation()
            .addElement(options.enteringEl)
            .duration(540)
            .easing('cubic-bezier(0.32,0.72,0,1)')
            .fill('both')
            .beforeRemoveClass('ion-page-invisible')
            .fromTo('opacity', '0', '1')

        const forwardAnimation = createAnimation().addAnimation([enterAnimation])

        // create an animation that expands and fades in
        const backEnteringAnimation = createAnimation()
            .addElement(options.enteringEl)
            .duration(540)
            .fill('both')
            .beforeRemoveClass('ion-page-invisible')

        const backLeavingAnimation = createAnimation()
            .addElement(options.leavingEl)
            .duration(540)
            .easing('cubic-bezier(0.32,0.72,0,1)')
            .fill('both')
            .beforeRemoveClass('ion-page-invisible')
            .fromTo('transform', 'scale(1)', 'scale(0.9)')
            .fromTo('opacity', '1', '0')
            .afterAddWrite(() => {
                options.leavingEl.style.setProperty('display', 'none')
            })
            .onFinish(() => {
                delay(() => {
                    options.leavingEl.style.setProperty('display', '')
                }, 100)
            })

        // return a collection of animations that can be used in a transition
        const backAnimation = createAnimation()
            .addAnimation([backEnteringAnimation, backLeavingAnimation])

        return options.direction === 'forward' || options.direction === 'none' ? forwardAnimation : backAnimation
    }

    function homepageAnimation(el, options) {
        // create an animation that expands and fades in
        const forwardEnteringAnimation = createAnimation()
            .addElement(options.enteringEl)
            .duration(540)
            .easing('cubic-bezier(0.32,0.72,0,1)')
            .fill('both')
            .beforeRemoveClass('ion-page-invisible')
            .fromTo('transform', 'scale(0.9)', 'scale(1)')
            .fromTo('opacity', '0', '1')

        // return a collection of animations that can be used in a transition
        const forwardAnimation = createAnimation()
            .addAnimation([forwardEnteringAnimation])

        // create an animation that expands and fades in
        const backEnteringAnimation = createAnimation()
            .addElement(options.enteringEl)
            .duration(540)
            .fill('both')
            .beforeRemoveClass('ion-page-invisible')

        const backLeavingAnimation = createAnimation()
            .addElement(options.leavingEl)
            .duration(540)
            .easing('cubic-bezier(0.32,0.72,0,1)')
            .fill('both')
            .beforeRemoveClass('ion-page-invisible')
            .fromTo('transform', 'scale(1)', 'scale(0.9)')
            .fromTo('opacity', '1', '0')
            .afterAddWrite(() => {
                options.leavingEl.style.setProperty('display', 'none')
            })
            .onFinish(() => {
                delay(() => {
                    options.leavingEl.style.setProperty('display', '')
                }, 100)
            })

        // return a collection of animations that can be used in a transition
        const backAnimation = createAnimation()
            .addAnimation([backEnteringAnimation, backLeavingAnimation])

        return options.direction === 'forward' || options.direction === 'none' ? forwardAnimation : backAnimation
    }

    function navigationAnimation(el, options) {
        const forwardEnteringEl = createAnimation()
            .addElement(options.enteringEl)
            .fromTo('transform', 'translateX(100%)', 'translateX(0%)')
            .duration(540)
            .easing('cubic-bezier(0.32,0.72,0,1)')
            .fill('both')
            .beforeRemoveClass('ion-page-invisible')

        const forwardLeavingEl = createAnimation()
            .addElement(options.leavingEl)
            .fromTo('transform', 'translateX(0%)', 'translateX(-15%)')
            .duration(540)
            .easing('cubic-bezier(0.32,0.72,0,1)')
            .fill('both')
            .beforeRemoveClass('ion-page-invisible')

        const forwardAnimation = createAnimation()
            .addAnimation([
                forwardEnteringEl,
                forwardLeavingEl,
            ])

        const backEnteringEl = createAnimation()
            .addElement(options.enteringEl)
            .fromTo('transform', 'translateX(-15%)', 'translateX(0%)')
            .duration(540)
            .easing('cubic-bezier(0.32,0.72,0,1)')
            .fill('both')
            .beforeRemoveClass('ion-page-invisible')

        const backLeavingEl = createAnimation()
            .addElement(options.leavingEl)
            .fromTo('transform', 'translateX(0%)', 'translateX(100%)')
            .duration(540)
            .easing('cubic-bezier(0.32,0.72,0,1)')
            .fill('both')
            .beforeRemoveClass('ion-page-invisible')
            .afterAddWrite(() => {
                options.leavingEl.style.setProperty('display', 'none')
            })
            .onFinish(() => {
                delay(() => {
                    options.leavingEl.style.setProperty('display', '')
                }, 100)
            })

        const backAnimation = createAnimation()
            .addAnimation([
                backEnteringEl,
                backLeavingEl,
            ])

        return options.direction === 'forward' ? forwardAnimation : backAnimation
    }

    function navigationForward(el, options) {
        options.direction = 'forward'
        return navigationAnimation(el, options)
    }

    function navigationBackward(el, options) {
        options.direction = 'back'
        return navigationAnimation(el, options)
    }

    return {
        launchAnimation,
        homepageAnimation,
        navigationAnimation,
        navigationForward,
        navigationBackward,
    }
}
