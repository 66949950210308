<template>
    <div class="flex flex-col h-full w-full overflow-hidden">
        <DTabs :type="tabType" @selected="handleSelected" padding flex class="h-full flex flex-col">
            <DTab
                v-for="(tab, index) in tabs"
                :active="isSelected(tab)"
                :name="tab.title"
                :key="`group-${index}`"
                class="min-h-0 basis-full"
            >
                <div v-if="tab.link.type === linkType.component" class="h-full overflow-auto">
                    <ModuleComponents
                        v-if="tab.link.component"
                        :modules="[tab.link.component]"
                    />
                </div>
                <div v-else class="flex justify-center pt-[100px]">
                    <UILoading class="w-12 h-12" />
                </div>
            </DTab>
        </DTabs>
    </div>
</template>

<script setup>
    import { DTabs, DTab } from '@digistorm/spark'
    import linkType from '@/constants/linkType'
    import ModuleComponents from '@/components/ModuleComponents.vue'

    const moduleStore = useModuleStore()
    const tabStore = useTabStore()

    const props = defineProps({
        config: Object,
    })

    const moduleHelpers = useModuleHelpers()

    const tabGroupId = ref(props.config.meta.id)

    const currentTabId = ref(null)

    const tabs = computed(() => get(props.config, 'data.tabs'))
    const tabType = computed(() => get(props.config, 'data.type'))

    const isSelected = (tab) => {
        // Use last selected tab for this module, only if id is defined on the tab group component
        // Note: this is remembered for the session, if the user reopens the module it might
        // require additional loading if the selected tab isn't preloaded in the request
        if (tabGroupId.value && currentTabId.value) {
            return currentTabId.value === tab.id
        }
        return tab.selected
    }

    const loadTab = (tabIndex) => {
        // Load tab if necessary
        const tab = tabs.value[tabIndex]
        if (tab?.link?.type && tab.link.type !== linkType.component) {
            moduleStore.loadContent(tab.link.url)
                .then(({ data }) => {
                    // Replace tab content with new component
                    tabs.value[tabIndex].link.component = data
                    tabs.value[tabIndex].link.type = linkType.component
                })
        }
    }

    const handleSelected = (index) => {
        const tab = tabs.value[index]
        if (tabGroupId.value) {
            tabStore.setCurrentTabId(tabGroupId.value, tab.id)
        }
        loadTab(index)
    }

    // Load current tab if necessary
    onBeforeMount(() => {
        currentTabId.value = tabStore.getCurrentTabId(tabGroupId.value)
        loadTab(findIndex(tabs.value, { id: currentTabId.value }))
    })

    onMounted(() => {
        moduleHelpers.setAnalyticsScreenFromConfig(props.config)
    })
</script>
