import { defineStore } from 'pinia'

import { api, mock } from '@/services'
import { useAppStore } from '@/stores/app'

export const useIdentityStore = defineStore('identity', {
    state: () => {
        return {
            loaded: false,
            identity: null,
        }
    },
    actions: {
        // Loading of the identity module content
        async load() {
            const { appId } = useAppStore()
            // If mocking use the mock content
            if (mock.mockingIdentity()) {
                this.identity = mock.content()
                return
            }
            await api.get(`/${appId}/identity`)
                .then(({ data }) => {
                    this.identity = data
                    this.loaded = true
                })
        },
    },
})
