<template>
    <div class="flex flex-col h-full border m-6 rounded-lg shadow-lg p-4">
        <div class="w-full flex justify-center my-4">
            <img :src="version.logo_file.url" alt="logo" class="h-16 object-contain" />
        </div>

        <div v-if="config.data.user_image_url" class="flex justify-center mb-4">
            <div class="w-[130px] h-[130px] rounded-lg bg-cover bg-center" :style="{ backgroundImage: `url(${config.data.user_image_url})` }" />
        </div>

        <div class="flex flex-col justify-center items-center flex-grow">
            <div v-if="config.data.name" class="text-lg font-medium text-center mb-2">
                {{ config.data.name }}
            </div>

            <div v-if="config.data.year_level" class="text-sm text-gray-600 text-center mb-4">
                {{ config.data.year_level }}
            </div>

            <template v-if="config.data.barcode_image_url">
                <img
                    :class="{
                        'max-h-[180px] ': config.data.type === 'qr_code',
                    }"
                    :src="config.data.barcode_image_url"
                    class="mb-2"
                    alt="barcode"
                />
                <p class="text-md font-medium">{{ config.data.id }}</p>
            </template>
        </div>
    </div>
</template>

<script setup>
    const versionStore = useVersionStore()
    const { version } = storeToRefs(versionStore)

    const props = defineProps({
        config: Object,
    })
</script>
