<template>
    <div class="flex flex-col items-start pt-10 px-8 w-full min-h-full flex-grow">
        <div class="flex-grow w-full">
            <img v-if="false" :src="config.layout.image_url" alt="logo" class="max-w-xs max-h-32 mb-10" />
            <div class="mb-10 text-left w-full">
                <h2 class="text-3xl font-bold mb-4">{{ config.layout.title }}</h2>
                <p class="text-gray-600">{{ config.layout.description }}</p>
            </div>
            <div class="w-full">
                <div class="grid grid-cols-4 gap-4">
                    <div
                        v-for="(icon, index) in icons"
                        :key="index"
                        :class="getOpacityClass(index)"
                        class="flex flex-col items-center justify-center p-4 bg-white rounded-lg"
                    >
                        <PhIcon weight="duotone" :icon="icon" icon-set="phosphor" :size="32" :color="primaryColor" />
                    </div>
                </div>
            </div>
        </div>
        <Teleport :to="footerMenu">
            <div class="px-8 pb-6 flex flex-col bg-[var(--background)] w-full mt-auto" data-cy="tour-footer">
                <UIButton class="mb-1" @click="emit('action-completed')">
                    {{ config.action_button_text }}
                </UIButton>
            </div>
        </Teleport>
    </div>
</template>

<script setup>
    import PhIcon from '@/components/app/PhIcon.vue'
    import { UIButton } from '@/components/ui'

    const emit = defineEmits(['action-completed'])

    const versionStore = useVersionStore()

    const { version } = storeToRefs(versionStore)

    const props = defineProps({
        config: Object,
        footerMenu: HTMLElement,
    })

    const icons = [
        'newspaper',
        'calendar-dots',
        'users',
        'books',
        'megaphone',
        'coffee',
        'clock-user',
        'chalkboard-teacher',
        'backpack',
        'bell',
        'soccer-ball',
        'medal',
    ]

    const primaryColor = computed(() => get(version.value, 'primary_color'))

    const getOpacityClass = (index) => {
        const rowIndex = Math.floor(index / 4)
        if (rowIndex === 1) {
            return 'opacity-60'
        }
        if (rowIndex === 2) {
            return 'opacity-20'
        }
        return ''
    }
</script>
