import { defineStore } from 'pinia'
import { v4 as uuidv4 } from 'uuid'
import { every } from 'lodash'

import { api, preferences } from '@/services'
import { useAppStore } from '@/stores/app'
import { useModuleStore } from '@/stores/module'
import { biometric } from '@/services/biometric'
import tourActionTypes from '@/constants/tourActionTypes'
import tourActionStatusTypes from '@/constants/tourActionStatusTypes'

export const useTourStore = defineStore('tour', {
    state: () => {
        return {
            loaded: false,
            tourStatus: [],
            stepModuleUuids: [],
            stepModules: {},
            selectedCategories: [],
        }
    },
    getters: {
        currentStepIndex(state) {
            const moduleStore = useModuleStore()
            const currentModuleId = moduleStore.currentModule?.id
            if (!currentModuleId) {
                return null
            }
            const currentIndex = state.stepModuleUuids.indexOf(moduleStore.currentModule.id)
            return currentIndex >= 0 ? currentIndex : null
        },
        currentStepUuid(state) {
            const moduleStore = useModuleStore()

            // Ensure the uuid is a tour step
            if (state.currentStepIndex === null) {
                return null
            }
            return moduleStore.currentModule.id
        },
        currentLayoutType() {
            return this.getModuleByIndex(this.currentStepIndex)?.data?.layout?.type
        },
    },
    actions: {
        // Loading of the tour module content
        async load() {
            const { appId } = useAppStore()

            await api.get(`/${ appId }/tour`)
                .then(({ data }) => {
                    forEach(data.steps, (step, index) => {
                        const stepUuid = uuidv4()
                        this.stepModuleUuids.push(stepUuid)
                        this.stepModules[stepUuid] = {
                            id: stepUuid,
                            data: step,
                        }
                    })
                    this.loaded = true

                    const onlyWelcome = every(this.stepModules, ({ data }) => data.layout?.type === 'WelcomeLayout')

                    // Skip tour if it's just the welcome page
                    if (onlyWelcome) {
                        this.stepModuleUuids = []
                        this.stepModules = {}
                    }
                })
        },
        completeTour() {
            const { appId } = useAppStore()
            this.stepModuleUuids = []
            this.stepModules = {}
            api.post(`/${ appId }/tour`, {
                actions: this.tourStatus,
            })
            this.storeCompleteTourStatus()
        },
        storeCompleteTourStatus() {
            preferences.setGlobalItem({ key: 'tour_completed', value: '1' })
        },
        handleAction(action, skipped) {
            if (!action) {
                return
            }
            this.tourStatus.push(
                {
                    action,
                    status: skipped ? tourActionStatusTypes.skipped : tourActionStatusTypes.completed,
                },
            )
        },
        getModuleByIndex(index) {
            if (!this.stepModuleUuids[index]) {
                return null
            }
            return this.stepModules[this.stepModuleUuids[index]]
        },
        async shouldLoad() {
            const versionStore = useVersionStore()

            return versionStore.version?.tour_enabled
                && !await preferences.getGlobalItem('tour_completed')
        },
        async shouldShowStep(module) {
            if (!module) {
                return false
            }

            const versionStore = useVersionStore()

            const completedAction = this.tourStatus.find((status) => status.action === module.data?.action_type && status.status === tourActionStatusTypes.completed)
            switch (module.data?.action_type) {
                case tourActionTypes.requestBiometricAuthentication:
                    return versionStore.version.biometrics_enabled && await biometric.canRequestBiometry() && !completedAction
                case tourActionTypes.requestPushNotificationPermission:
                    return await usePushNotifications().canRegister() && !completedAction
                case tourActionTypes.requestSubscriptionSelection:
                    return this.selectedCategories.length > 0
                default:
                    return true
            }
        },
        async getNextStepIndex(stepIndex) {
            const nextModule = this.getModuleByIndex(stepIndex + 1)
            if (!nextModule) {
                return null
            }

            return await this.shouldShowStep(nextModule)
                ? stepIndex + 1
                : this.getNextStepIndex(stepIndex + 1)
        },
        async getNextStepModule(index = this.currentStepIndex) {
            const nextIndex = await this.getNextStepIndex(index)
            return this.getModuleByIndex(nextIndex)
        },
        async getFirstStepModule() {
            return this.getNextStepModule(-1)
        },
    },
})
