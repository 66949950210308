<template>
    <div data-cy="identity" class="relative h-full text-gray-700 flex flex-col" :class="{ 'pt-[var(--beyond-safe-area-inset-top)]': !isNativePlatform }">
        <div class="pt-5 text-gray-900 font-medium text-xl text-center">
            {{ identificationData.title }}
        </div>
        <div v-if="loading" class="py-10 flex items-center justify-center h-full">
            <UILoading class="w-12 h-12" />
        </div>
        <div v-else-if="error" class="p-4">
            <DAlert type="danger" title="Error">
                Please try again later
            </DAlert>
        </div>
        <ModuleComponents
            v-else-if="identity"
            :modules="[identity]"
        />
    </div>
</template>

<script setup>
    import { DAlert } from '@digistorm/spark'
    import { Capacitor } from '@capacitor/core'
    import { statusBar, mock } from '@/services'
    import ModuleComponents from '@/components/ModuleComponents.vue'
    import { useIdentityStore } from '@/stores/identity'

    const props = defineProps({
        content: Object,
    })

    const identityStore = useIdentityStore()
    const versionStore = useVersionStore()

    const { identity } = storeToRefs(identityStore)
    const { version } = storeToRefs(versionStore)

    const loading = ref(null)
    const error = ref(null)

    const isNativePlatform = computed(() => {
        return Capacitor.isNativePlatform()
    })

    const identificationData = computed(() => get(version.value, 'identification_settings'))

    onMounted(() => {
        statusBar.setTheme()
    })

    onActivated(() => {
        statusBar.setTheme()
    })
</script>
