<template>
    <div
        v-if="dashboard"
        ref="dashboard"
        class="relative min-h-full text-gray-700 flex flex-col"
        :style="dashboardBackground"
        data-cy="dashboard"
    >
        <DashboardBlock
            v-for="(item, index) in dashboard.items"
            :key="item.id"
            :item="item"
            :index="index"
            :total="dashboard.items.length"
        />
    </div>
</template>

<script setup>
    import { statusBar } from '@/services'
    import { useMoreStore } from '@/stores/more'

    const props = defineProps({
        dashboard: Object,
    })

    const authStore = useAuthStore()
    const badgeStore = useBadgeStore()
    const dashboardStore = useDashboardStore()
    const moreStore = useMoreStore()
    const identityStore = useIdentityStore()
    const versionStore = useVersionStore()

    const { version } = storeToRefs(versionStore)

    const dashboardBackground = computed(() => {
        if (isEmpty(props.dashboard)) {
            return {}
        }

        const background = get(props.dashboard, 'data.background_image_file')
        let color = get(props.dashboard, 'data.background_color')

        if (isEmpty(color)) {
            color = '#fff'
        }

        if (background) {
            return {
                backgroundImage: `url(${background.url})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }
        }

        return {
            backgroundColor: color,
        }
    })

    const handleLogin = () => {
        // Refresh badges that failed
        badgeStore.refreshAuthFailures()

        // Reload the dashboard
        dashboardStore.load()
        // Reload the more module data
        moreStore.load()
        // Reload the profile data
        identityStore.load()
    }

    const updateStatusBar = () => {
        statusBar.setTheme(version.value.primary_color)
    }

    onMounted(() => {
        authStore.events.on('login', handleLogin)

        updateStatusBar()
    })

    onActivated(() => {
        updateStatusBar()
    })

    onUnmounted(() => {
        authStore.events.off('login', handleLogin)
    })
</script>

<style lang="scss" scoped>

</style>
